import "babel-polyfill"//js兼容处理
import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';

import axios from 'axios'
import VueAxios from 'vue-axios'
import './view/modules/iconfont/iconfont.js';

const app = createApp(App);
app.use(router);

import store from './store';
app.use(store)

app.use(VueAxios, axios)
import './http';

app.mount('#app');

// require('es6-promise').polyfill();
import axios from 'axios';
// import store from '../store'
import { Toast,Dialog } from "vant";
// import { readymd5 } from '../assets/js/utils'

axios.defaults.baseURL = "/api";
axios.defaults.timeout = 30000;
axios.defaults.headers = { 'Content-Type': 'application/json' }

// 请求拦截器
axios.interceptors.request.use(
  config => {
    Toast.loading({
      message: '请求中',
      forbidClick: true,
    })
    // store.state.ishttploadingshow = true
    /*     if (store.state.count === 0) {
          console.log('request 0', config)
        } */
    // console.log(config.url)
    // if (config.url == '/api/overseas/balance/order') {
      // config.data = readymd5(config.data)
    // } else {
      // config.data = config.data
    // }
    return config
  },
  error => {
    Dialog.alert({
      message: '网络请求异常,请检查网络后再试',
      closeOnPopstate: false
    })
    Toast.clear("clearAll")
    return Promise.reject(error)
  }
)

// 返回拦截器
axios.interceptors.response.use(
  response => {
    // store.state.ishttploadingshow = false
    // if (store.state.count === 0) {
    //   console.log('response 0', response.data.resCode)
    // }

    // if (response.config.url == '/api/overseas/cashier/balance/pointCard' || response.config.url == '/api/overseas/cashier/balance/pointCard2' || response.config.url == '/api/overseas/cashier/balance/pointCard3' || response.config.url == '/api/overseas/cashier/balance/bizha/pointCard' || response.config.url == '/api/overseas/cashier/balance/pay' || response.config.url == '/api/overseas/cashier/balance/query') {
    //   return response.data
    // }
    // console.log(response.data)
    // response.data.respCode="99"
    if (response.data.body) {//判断外层返回码是否成功
      if(response.data.body.returnCode== "MCG00000"){//判断内部返回码是否成功
        return response.data
      } else {
        Toast.clear("clearAll")
        Dialog.alert({
          message: '网络连接异常,请稍后再试'+response.data.body.returnCode,
          closeOnPopstate: false
        })
        return Promise.reject(response.data)
      }
    }else {
      Dialog.alert({
        message: '网络连接异常,请稍后再试'+response.data.respCode,
        closeOnPopstate: false
      })
      Toast.clear("clearAll")
      // Toast.loading({
      //   message: '网络连接异常,请稍后再试!',
      //   //duration: 5, // 持续展示加载中
      //   forbidClick: true,
      // });
      return Promise.reject(response.data)
    }
    
  },
  error => {
    Dialog.alert({
      message: '网络连接异常,请稍后再试！',
      closeOnPopstate: false
    })
    Toast.clear("clearAll")
    return Promise.reject(error.response.data)
  }
)


export default axios

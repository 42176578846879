<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>

export default ({
  data () {
    return {
      active: ""
    }
  },
  mounted () {
    this.$nextTick(function () {
      // 仅在渲染整个视图之后运行的代码
    })
  },
  created () {

  },
  setup () {

  },
})
</script>

<style>
body {
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
}
</style>
